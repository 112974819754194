import { bootstrapSystemOneWithoutRedux } from "common/bootstrapSystemOne";
import SystemOneApp from "common/SystemOneApp";
import React from "react";
import { createRoot } from "react-dom/client";
import { type Store } from "redux";
import WarningPanel from "warning/WarningPanel";

declare const viewModel: server.viewmodel.WarningViewFrontModel;

jQuery(() => {
  const pageComponents = (s: Store<any>) => {
    const root = createRoot(document.getElementById("app"));
    root.render(
      <SystemOneApp store={s}>
        <WarningPanel {...viewModel} />
      </SystemOneApp>
    );
  };

  bootstrapSystemOneWithoutRedux(pageComponents);
});