import React from "react";
import { UserAvatar } from "./Atoms/Avatar";
import { Loading } from "./panels";
import classNames from "classnames";
import { useViewport } from "utilities/hookutils";

interface IUserAccountCardProps {
  user: server.dto.UserSmall
  className?: string
  onClick?: () => void
  isLoading?: boolean
}

const UserAccountCard = ({ user, className, onClick, isLoading }: IUserAccountCardProps) => {
  const viewport = useViewport();

  const cssClasses = classNames("card-floating-bg padding-large flex align-center gap", className);

  return (<div
    onClick={onClick}
    className={cssClasses}
    key={user.userId}
    style={{ minWidth: viewport !== "mobile" ? 350 : "none" }}>

    <div>
      <UserAvatar user={user} size="avatar-45" />
    </div>

    <div className="grow1">
      <div>{user.fullName}</div>
    </div>

    {isLoading && <div>
      <Loading />
    </div>}
  </div>);
};

export default UserAccountCard;