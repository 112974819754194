import { SystemOneLogo } from "components/Atoms/brand/SystemOneLogo";
import { ButtonPrimary, ButtonSecondary } from "components/Atoms/Button";
import UserAccountCard from "components/UserAccountCard";
import React from "react";
import { Message, type SemanticCOLORS } from "semantic-ui-react";

const WarningPanel = (props: server.viewmodel.WarningViewFrontModel) => {
  let signOutUrl: string;

  switch (props.displaySignedInAs) {
    case "yesSignOutToDashboard":
      signOutUrl = window.CoreApi.Authentication.Signout.absoluteUrl("");
      break;
    case "yesSignOutWithCurrentPageRedirect":
      signOutUrl = window.CoreApi.Authentication.Signout.absoluteUrl(window.location.href);
      break;
  }

  return <div className="s1_main-panel small warning-panel">
    <div className="s1_main-panel-content">
      <div className="s1_main-panel-title">
        <h1>{props.title}</h1>
      </div>

      <Message
        color={props.messageColor as SemanticCOLORS}
      >
        {props.messageHtml}
      </Message>

      {(props.secondaryButton || props.primaryButton) && <div className="s1_main-panel-buttons">
        {props.secondaryButton && <div>
          <ButtonSecondary
            href={props.secondaryButton.link}
            label={props.secondaryButton.text}
          />
        </div>}
        {props.primaryButton && <div>
          <ButtonPrimary
            label={props.primaryButton.text}
            href={props.primaryButton.link}
          />
        </div>}
      </div>}

      {(site.currentUser) && (signOutUrl) && <div>
        <fieldset>
          <legend>{RESX.Account.YouAreSignedInAs}</legend>

          <UserAccountCard user={site.currentUser} isLoading={false} />

          <div className="margin-top">
            <a href={signOutUrl}
              className="button-new primary fluid">{RESX.GeneralLabels.SignOut}</a>
          </div>
        </fieldset>
      </div>}
    </div>

    <div className="s1_main-panel-logo">
      <a href={site.promoSiteUrl} target="_blank" rel="noreferrer">
        <SystemOneLogo />
      </a>
    </div>
  </div>;
};

export default WarningPanel;